export const ABOUT_PAGE_FIELDS = [
  'header_title',
  'header_banner',
  'welcome_title',
  'welcome_paragraph_1',
  'welcome_paragraph_2',
  'welcome_image_1',
  'welcome_image_2',
  'welcome_image_3',
  'who_we_are_title',
  'who_we_are_paragraph',
  'who_we_are_image',
  'mosaic',
  'slider_title',
  'slider_subtitle',
  'slider',
  'downloads_title',
  'downloads_paragraph',
  'downloads_image',
  'cta_download',
  'dealership_call_to_action.text',
  'dealership_call_to_action.url',
  'dealership_call_to_action.type',
  'dealership_call_to_action.icon',
  'dealership_call_to_action.image',
  'dealership_call_to_action.heading',
  'dealership_call_to_action.subheading',
  'dealership_call_to_action.status',
];

export const PAIA_DOCUMENT = '7b1c0552-2b49-40e6-9296-22815e45d204';
export const POPIA_NOTICE_DOCUMENT = '3a95ed0a-bdd1-4353-bc0b-a65d27de3f65';

import { DealerItem } from '@/types/dealers/dealers.types';
import { PanelbeaterItem } from '@/types/panelbeaters/panelbeaters.types';
import { UserLocation } from '@/types/user/user.types';

interface CurrentLocation {
  lat: number;
  lng: number;
}

export const getCurrentLocation = (): Promise<CurrentLocation> => {
  return new Promise((resolve, reject) => {
    let lat;
    let lng;
    const locationError =
      'Your browser is blocking the use of your location, please enable this and try again.';

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position.coords) {
          lat = position.coords.latitude;
          lng = position.coords.longitude;
          resolve({ lat, lng });
        }
      },
      () => {
        reject(locationError);
      },
    );
  });
};

export const sortByDistance = (
  userLocation: UserLocation,
  dealers: DealerItem[] | PanelbeaterItem[],
): any[] => {
  if (!userLocation || !userLocation.lat || !userLocation.lng) {
    return dealers;
  }

  const dealersWithDistances = dealers.map((dealer) => {
    if (dealer.lat && dealer.lng) {
      return {
        dealer,
        distance: Math.sqrt(
          Math.pow(dealer.lat - userLocation.lat, 2) +
            Math.pow(dealer.lng - userLocation.lng, 2),
        ),
      };
    }

    return {
      dealer,
      distance: null,
    };
  });

  const sorted = dealersWithDistances.sort((a, b) => {
    if (a.distance === null || a.distance === undefined) {
      return 1;
    }
    if (b.distance == null || b.distance === undefined) {
      return -1;
    }
    return a.distance - b.distance;
  });

  return sorted.map((item) => item.dealer);
};

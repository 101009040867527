import { atom } from 'recoil';

import { dealerLocation } from '@/constants/dealers/dealers.constants';
import { DealerItem } from '@/types/dealers/dealers.types';
import { UserLocation } from '@/types/user/user.types';

export const dealersInfoState = atom<DealerItem[]>({
  key: 'dealersList',
  default: [],
});

export const dealersBodyTypeState = atom({
  key: 'dealerBodyType',
  default: '',
});

export const kiaIndependentState = atom<string[]>({
  key: 'kiaIndependentState',
  default: [],
});

export const selectedDealerLocation = atom({
  key: 'dealerLocation',
  default: {
    id: '',
    ...dealerLocation,
  },
});

export const currentPlaceLocation = atom({
  key: 'currentPlaceLocation',
  default: {
    name: '',
    lat: 0,
    lng: 0,
  },
});

export const currentGeoLocation = atom<UserLocation>({
  key: 'currentDealerLocation',
  default: {
    lat: 0,
    lng: 0,
  },
});

export const listOfDealersState = atom<DealerItem[]>({
  key: 'listOfDealers',
  default: dealersInfoState,
});

export const listOfDealersByLocationState = atom<DealerItem[]>({
  key: 'listOfDealersByLocation',
  default: [],
});

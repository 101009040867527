import { useRecoilState } from 'recoil';

import {
  currentGeoLocation,
  currentPlaceLocation,
  dealersBodyTypeState,
  dealersInfoState,
  kiaIndependentState,
  listOfDealersByLocationState,
  listOfDealersState,
  selectedDealerLocation,
} from '@/states/dealers/dealers.state';


export function useDealersInfo() {
  const [dealersInfo, setDealersInfo] = useRecoilState(dealersInfoState);
  const [dealerBodyType, setDealerBodyType] =
    useRecoilState(dealersBodyTypeState);
  const [kiaIndependentDealerships, setKiaIndependentDealerships] =
    useRecoilState(kiaIndependentState);
  const [listOfDealers, setListOfDealers] = useRecoilState(listOfDealersState);
  const [dealershipInfoList, setDealershipInfoList] =
    useRecoilState(dealersInfoState);
  const [listOfDealersByLocation, setListOfDealersByLocation] = useRecoilState(
    listOfDealersByLocationState,
  );

  return {
    dealersInfo,
    setDealersInfo,
    kiaIndependentDealerships,
    setKiaIndependentDealerships,
    listOfDealers,
    setListOfDealers,
    dealershipInfoList,
    setDealershipInfoList,
    listOfDealersByLocation,
    setListOfDealersByLocation,
    dealerBodyType,
    setDealerBodyType,
  };
}

export function useSelectedDealerLocation() {
  const [selectedLocation, setSelectedLocation] = useRecoilState(
    selectedDealerLocation,
  );

  return {
    selectedLocation,
    setSelectedLocation,
  };
}

export function useGeoLocation() {
  const [userLocation, setUserLocation] = useRecoilState(currentGeoLocation);
  const [placeLocation, setPlaceLocation] =
    useRecoilState(currentPlaceLocation);

  return {
    userLocation,
    setUserLocation,
    placeLocation,
    setPlaceLocation,
  };
}

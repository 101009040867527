import { LeadFormType } from '@/app/_components/modals/enquire-forms/enquiry-forms/enquiry-forms';
import {
  PAIA_DOCUMENT,
  POPIA_NOTICE_DOCUMENT,
} from '@/constants/about/about.constants';
import { getDownloadLink } from '@/services/link.service';
import { RoutesConfig } from '@/types/navigation/navigation.types';

const mainOffers = [
  {
    offerType: 'New Cars',
    id: 1,
    link: '/new-cars',
  },
  {
    offerType: 'Demo Cars',
    id: 2,
    link: '/used-cars/demo',
  },
  {
    offerType: 'Used Cars',
    id: 3,
    link: '/used-cars/used',
  },
  {
    offerType: 'Car Finder',
    id: 4,
    link: '/car-finder',
  },
  {
    offerType: 'Find a Dealership',
    id: 5,
    link: '/find-a-dealer',
  },
  {
    offerType: 'Kia Specials',
    id: 6,
    link: '/specials',
  },
  {
    offerType: 'Choose Kia',
    id: 7,
    link: '/choosekia',
  },
  {
    offerType: 'Kia Tsamaya',
    id: 8,
    link: '/kiatsamaya',
  },
  {
    offerType: 'Sitemap',
    id: 9,
    link: '/sitemap',
  },
  {
    offerType: 'After Sales',
    id: 10,
    link: '/after-sales',
  },
  {
    offerType: 'Blog',
    id: 11,
    link: '/blog',
  },
];

const specialsCoreLink = '/specials/';
const specialsList = [
  {
    specialName: 'Used Car Specials',
    id: 0,
    link: '/specials',
  },
  {
    specialName: 'Picanto',
    id: 1,
    link: `${specialsCoreLink}picanto`,
  },
  {
    specialName: 'Rio',
    id: 2,
    link: `${specialsCoreLink}rio`,
  },
  {
    specialName: 'Seltos',
    id: 3,
    link: `${specialsCoreLink}seltos`,
  },
  {
    specialName: 'Sportage',
    id: 4,
    link: `${specialsCoreLink}sportage`,
  },
  {
    specialName: 'Sorento',
    id: 5,
    link: `${specialsCoreLink}sorento`,
  },
  {
    specialName: 'Grand Sedona',
    id: 6,
    link: `${specialsCoreLink}grand-sedona`,
  },
];

export const shoppingToolsList = [
  {
    title: 'Book a Service',
    isEnquireForm: true,
    leadFormName: 'Service',
  },
  {
    title: 'Buy Parts & Accessories',
    link: 'https://kiaparts.co.za/',
    isOpenInNewTab: true,
  },
  {
    title: 'Find certified Panelbeaters',
    link: '/panelbeaters',
  },
  {
    title: 'Retail Pricing',
    link: 'https://www.kia.co.za/assets/brochures/Kia_Retail_Pricelist.pdf',
    isDownload: true,
  },
  {
    title: 'Vin Check',
    link: 'https://campaigncheck.kia.co.za/',
    isOpenInNewTab: true,
  },
];

export const servicesList = [
  {
    id: 0,
    title: 'Book a Test Drive',
    isEnquireForm: true,
    leadFormType: 'test-drive',
  },
  {
    id: 1,
    title: 'Book a Service',
    isEnquireForm: process.env.NEXT_PUBLIC_BOOK_A_SERVICE_URL ? false : true,
    link: process.env.NEXT_PUBLIC_BOOK_A_SERVICE_URL,
    leadFormType: 'service',
  },
  {
    id: 2,
    title: 'Parts & Accessories',
    link: '/after-sales/parts-and-accessories',
    isOpenInNewTab: true,
  },
  {
    id: 3,
    title: 'Find a Panelbeater',
    link: '/panelbeaters',
  },
  {
    id: 4,
    title: 'VIN Check',
    link: '/vin-check',
  },
  {
    id: 5,
    title: 'Warranty Parameters',
    link: '/warranty-parameters',
  },
  {
    id: 6,
    title: 'Your right to choose',
    link: '/your-right-to-choose',
  },
] as {
  id: number;
  title: string;
  isEnquireForm?: boolean;
  leadFormType?: LeadFormType;
  isOpenInNewTab?: boolean;
}[];

export const legalList = [
  {
    contactName: 'Privacy Policy',
    id: 0,
    link: '/legal/privacy-policy',
  },
  {
    contactName: 'Terms & Conditions',
    id: 1,
    link: '/legal/terms-conditions',
  },
  {
    contactName: 'Cookie Management',
    id: 2,
    link: '/legal/privacy-policy#cookie-policy',
  },
  {
    contactName: 'PAIA Manual',
    id: 3,
    link: getDownloadLink(PAIA_DOCUMENT),
    isDownload: true,
  },
  {
    contactName: 'POPIA Notice',
    id: 4,
    link: getDownloadLink(POPIA_NOTICE_DOCUMENT),
    isDownload: true,
  },
];

export const contactList = [
  {
    title: 'Contact Us',
    id: 0,
    link: '/find-a-dealer',
  },
  {
    title: 'Customer Care Line:\n 010 595 3228',
    id: 1,
    link: 'tel:+27 10 595 3228',
  },
  {
    title: 'Roadside Assist:\n 0861 542 000',
    id: 2,
    link: 'tel: +27 861 542 000',
  },
];

export const newMainOffers = [
  {
    offerType: 'New Vehicles',
    id: 1,
    link: '/new-cars',
  },
  {
    offerType: 'Certified Pre-Owned',
    id: 2,
    link: '/used-cars',
    props: {
      'data-cy': 'certified-pre-owned',
    },
  },
  {
    offerType: 'Why Kia',
    id: 3,
    link: '/about',
  },
  {
    offerType: 'Experience Kia',
    id: 4,
    link: '/blog',
  },
  {
    offerType: 'Financial Services',
    id: 5,
    link: 'https://financialservices.kia.co.za/',
  },
  {
    offerType: 'Find a Dealership',
    id: 6,
    link: '/find-a-dealer',
  },
  {
    offerType: 'Kia Tsamaya',
    id: 7,
    link: '/kiatsamaya',
  },
  {
    offerType: 'Choose Kia',
    id: 8,
    link: '/choosekia',
  },
];
export function useRoutesConfig(): RoutesConfig {
  return {
    root: {
      path: '/',
      name: 'Homepage',
      footerData: { mainOffers, specialsList, contactList },
    },

    dealerships: {
      path: '/dealerships',
      name: 'Dealerships',
    },

    aboutKiaRetail: { path: '/about-retail', name: 'About Kia Retail' },
    afterSales: { path: '/after-sales', name: 'After Sales' },
    blog: { path: '/blog', name: 'Blog' },
    newCars: { path: '/new-cars', name: 'New Cars' },
    carFinder: { path: '/car-finder', name: 'Car Finder' },
    specials: { path: '/promotions', name: 'Promotions' },
    specialsAll: { path: '/promotions', name: 'All Promotions' },
    specialsUsedCars: {
      path: '/promotions/used-cars',
      name: 'Used Car Promotions',
    },
    specialsNewCars: {
      path: '/promotions/new-cars',
      name: 'New Car Promotions',
    },
    specialsSpectacularSavingsOffers: {
      path: '/specials/type?special_type=offer',
      name: 'Spectacular Savings Offers',
    },
    newKiaPriceCrushSpecials: {
      path: '/new-kia-price-crush-specials',
      name: 'New Kia Price Crush Specials',
    },
    specialsDiscounted: {
      path: '/specials/type?special_type=discount',
      name: 'Discounted Deals',
    },
    newSpecials: {
      path: '/specials/new-kia-specials',
      name: 'New Kia Specials',
    },
    buyWith: { path: '/blog', name: 'Blog' },
    aboutKiaKonfidence: {
      path: '/about-kia-konfidence',
      name: 'About Kia Konfidence',
    },
    usedCarsDealerships: { path: '/used-cars/dealership', name: 'Dealerships' },
    dealershipDetail: { path: '/dealerships', name: 'Dealership Detail' },
    finance: { path: '/new-cars#finance', name: 'Kia Finance' },
    financeNewCars: { path: '/new-cars#finance', name: 'Finance Calculator' },
    about: { path: '/about-retail', name: 'About Kia' },
    findDealer: { path: '/find-a-dealer', name: 'Find a Dealer' },
    findPanelbeater: {
      path: '/panelbeaters',
      name: 'Find a Panelbeater',
    },
    contactUs: { path: '/contact-us', name: 'Contact Us' },
    usedCarsWithSpecialOffers: {
      path: '/specials/used-cars',
      name: 'Used Cars with Special Offers',
    },
    konfidenceUsedCarsWithSpecialOffers: {
      path: '/specials/used-cars?type=konfidenceOrUsed',
      name: 'Used Cars with Special Offers',
    },
    demoCarsWithSpecialOffers: {
      path: '/specials/used-cars?type=demo',
      name: 'Demo Cars with Special Offers',
    },
    kiaPromise: { path: '/about-retail', name: 'Kia Promise' },
    termsConditions: {
      path: '/terms-conditions',
      name: 'Terms and Conditions',
    },
    privacyPolicy: {
      path: '/privacy-policy',
      name: 'Privacy Policy',
    },
    // used cars config
    allUsedCars: { path: '/used-cars', name: 'All Used Cars' },
    allUsedCarsDemo: { path: '/used-cars/demo', name: 'All Demo Cars' },
    allDemoCars: { path: '/used-cars/demo', name: 'All Demo Cars' },
    usedCars: { path: '/used-cars', name: 'Certified Pre-Owned' },
    demo: { path: '/used-cars/demo', name: 'Demo Cars' },
    searchResults: { path: '/used-cars/search', name: 'Search Results' },
    searchResultsUsed: {
      path: '/used-cars/search',
      name: 'Search Results',
    },
    searchResultsDemo: {
      path: '/used-cars/demo/search',
      name: 'Search Results',
    },
    brand: { path: '/used-cars', name: 'Brands' },
    brandUsed: { path: '/used-cars', name: 'Brands' },
    brandDemo: { path: '/used-cars/demo', name: 'Brands' },
    body: { path: '/used-cars/%body%-bodytype', name: 'Body Types' },
    bodyUsed: { path: '/used-cars/%body%-bodytype', name: 'Body Types' },
    bodyDemo: { path: '/used-cars/demo/%body%-bodytype', name: 'Body Types' },
    location: { path: '/used-cars/provinces', name: 'Provinces' },
    locationUsed: { path: '/used-cars/provinces', name: 'Provinces' },
    locationDemo: { path: '/used-cars/demo/provinces', name: 'Provinces' },
    brands: { path: '/used-cars', name: 'Brands' },
    brandsUsed: { path: '/used-cars', name: 'Brands' },
    brandsDemo: { path: '/used-cars/demo', name: 'Brands' },
    provinces: { path: '/used-cars/provinces', name: 'Provinces' },
    provincesUsed: { path: '/used-cars/provinces', name: 'Provinces' },
    provincesDemo: { path: '/used-cars/demo/provinces', name: 'Provinces' },
    prices: { path: '/used-cars/prices', name: 'Prices' },
    pricesUsed: { path: '/used-cars/prices', name: 'Prices' },
    pricesDemo: { path: '/used-cars/demo/prices', name: 'Prices' },
    types: { path: '/used-cars', name: 'Types' },
    typesUsed: { path: '/used-cars/body-types', name: 'Types' },
    typesDemo: { path: '/used-cars/demo/body-types', name: 'Types' },
    //
    models: { path: '/models', name: 'Models' },
    priceCrush: {
      path: '/specials/type?special_type=discount',
      name: 'Price Crush Specials',
    },
    offers: {
      path: '/specials/new-cars/kia',
      name: 'Offers',
    },
    sitemap: {
      path: '/sitemap',
      name: 'Sitemap',
    },
    priceCrushSpecial: {
      path: '/specials/price-crush-specials',
      name: 'Price Crush Specials',
    },
    specialsSpectacularSavingsOffer: {
      path: '/specials/spectacular-savings-offers',
      name: 'Spectacular Savings Offers',
    },
  };
}

export function useRoutesNewConfig() {
  return {
    root: {
      path: '/',
      name: 'Homepage',
      footerData: { legalList, newMainOffers, servicesList, contactList },
    },
  };
}
